import { createActionGroup, props } from '@ngrx/store';

export const SharedAction = createActionGroup({
  source: 'Shared',
  events: {
    'Set Loading': props<{ status: boolean }>(),
    'Set Agency': props<{ agency: string }>(),
    'Set Position': props<{position: string}>(),
    'Set Position Branch': props<{positionBranch: string}>(),
  },
});
